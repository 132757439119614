import gsap from "gsap";

const setAccordion = () => {
   const box = document.querySelectorAll(".js-accordion-box");

   box.forEach(el => {
      const btn = el.querySelector(".js-accordion-head"),
         content = el.querySelector(".js-accordion-content"),
         arrow = el.querySelector(".faq-item__q__arrow");

      let open = false

      btn.addEventListener('click', () => {
         if (open) {
            gsap.to(content, {
               height: 0,
               direction: .6,
               ease: "Quint.easeOut",
            })
            gsap.to(arrow, {
               rotate: 0,
               direction: .6,
               ease: "Quint.easeOut",
            })
         } else {
            gsap.to(content, {
               height: 'auto',
               direction: .6,
               ease: "Quint.easeOut",
            })
            gsap.to(arrow, {
               rotate: 180,
               direction: .6,
               ease: "Quint.easeOut",
            })
         }
         open = !open
      })
   });
};

export default setAccordion;
