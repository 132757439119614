// import setBeforeAfterSlider from "./_modules/setBeforeAfterSlider";
// import setDrSlider from "./_modules/setDrSlider";
// import setFaqToggle from "./_modules/setFaqToggle";
import setPhotoSlider from "./_modules/setPhotoSlider";
import setAccordion from "./_modules/setAccordion";

window.addEventListener('load', () => {
  // setBeforeAfterSlider();
  // setFaqToggle();
  setPhotoSlider();
  // setDrSlider();
  setAccordion();
})