import $ from 'jquery';
import 'slick-carousel';

const setPhotoSlider = () => {
  $('.photo-slider').slick({
    // dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnFocus: false,//フォーカスで一時停止
    pauseOnHover: false,//マウスホバーで一時停止
    pauseOnDotsHover: false//ドットナビをマウスホバーで一時停止
  });
  $(".photo-slider").prepend($(".photo-slider .slick-dots"))

  $(".photo-thumb").slick({
    slidesToShow: 4,
    // initialSlide: 2,
    asNavFor: ".photo-slider",
    focusOnSelect: true,
  });

  $(".photo-thumb").on('afterChange', function(slick,currentSlide,nextSlide) {
    $(".photo-thumb .photo-thumb-img").addClass('is-change')
  });
};

export default setPhotoSlider;
